<template>
	<div class="innovation-capability-form">
		<h4 class="form-title out-title">完善信息，获取完整评估报告</h4>
		<el-form autocomplete="off" label-width="210px">
			<div>
				<h5 class="form-title">一、基本信息</h5>
				<el-form-item label="产业领域：">
					<el-select placeholder="请选择" v-model="field">
						<el-option label="电子信息" value="1"></el-option>
						<el-option label="生物与新医药" value="2"></el-option>
						<el-option label="航空材料" value="3"></el-option>
						<el-option label="高新技术服务" value="4"></el-option>
						<el-option label="新能源与节能" value="5"></el-option>
						<el-option label="资源与环境" value="6"></el-option>
						<el-option label="先进制造与自动化" value="7"></el-option>
						<el-option label="新材料" value="8"></el-option>
						<el-option label="其他" value="9"></el-option>
					</el-select>
				</el-form-item>
				<!---->
			</div>

			<div>
				<h5 class="form-title">二、科技成果</h5>
				<el-form-item label="年平均科技成果转化：">
					<el-select placeholder="请选择" v-model="change">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="高新技术产品(服务)占比情况：">
					<el-select placeholder="请选择" v-model="mix">
						<el-option label="≥60%" value="1"></el-option>
						<el-option label="<60%" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="产品认定情况：">
					<el-select placeholder="请选择" v-model="affirm">
						<el-option label="高新技术产品" value="1"></el-option>
						<el-option label="重点新产品" value="2"></el-option>
						<el-option label="名牌产品" value="3"></el-option>
						<el-option label="其他" value="4"></el-option>
						<el-option label="无" value="5"></el-option>
					</el-select>
				</el-form-item>
			</div>

			<!-- <div>
        <h5 class="form-title">三、研发团队</h5>
        <el-form-item label="职工总数：">
          <el-input type="number"></el-input>
        </el-form-item>
        <el-form-item label="研发人员数：">
          <el-input type="number"></el-input>
        </el-form-item>
      </div> -->
			<div>
				<h5 class="form-title">三、组织管理</h5>
				<div class="form-item-content">
					<p class="label">1.是否制定了企业研究开发的组织管理制度、建立了研发投入核算体系、编制了研发费用辅助账</p>
					<el-radio-group v-model="satisfy">
						<el-radio label="6">3项都满足</el-radio>
						<el-radio label="4">满足2项</el-radio>
						<el-radio label="2">满足1项</el-radio>
						<el-radio label="0">都不满足</el-radio>
					</el-radio-group>
				</div>
				<div class="form-item-content">
					<p class="label">2.是否设立研发机构、是否开展产学研合作</p>
					<el-radio-group v-model="work">
						<el-radio :label="6">设立研发机构并开展产学研合作</el-radio>
						<el-radio :label="3">设立研发机构但无产学研合作</el-radio>
						<el-radio :label="0">无研发机构无产学研合作</el-radio>
					</el-radio-group>
				</div>
				<div class="form-item-content">
					<p class="label">3.是否建立了科技成果转化的组织实施与激励奖励制度，建立开放式的创新创业平台</p>
					<el-radio-group v-model="build">
						<el-radio :label="4">建立了科技成果转化的组织实施与激励奖励制度，且有开放式的创新创业平台</el-radio>
						<el-radio :label="2">建立了科技成果转化的组织实施与激励奖励制度，无开放式的创新创业平台</el-radio>
						<el-radio :label="0">没有科技成果转化的组织实施与激励奖励制度，无开放式的创新创业平台</el-radio>
					</el-radio-group>
				</div>
				<div class="form-item-content">
					<p class="label">4.是否建立了科技人员的培养进修、职工技能培训、优秀人才引进，以及人才绩效评价奖励制度</p>
					<el-radio-group v-model="system">
						<el-radio :label="4">是</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div>
				<h5 class="form-title">四、财务信息</h5>
				<el-form-item label="近三年净资产：" class="more-value">
					<el-input v-model="one" type="number">
						<template slot="prepend">
							2018
						</template>
						<template slot="append">
							万元
						</template>
					</el-input>
					<el-input v-model="two" type="number">
						<template slot="prepend">
							2019
						</template>
						<template slot="append">
							万元
						</template>
					</el-input>
					<el-input v-model="three" type="number">
						<template slot="prepend">
							2020
						</template>
						<template slot="append">
							万元
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="近三年销售收入：" class="more-value">
					<el-input v-model="four" type="number">
						<template slot="prepend">
							2018
						</template>
						<template slot="append">
							万元
						</template>
					</el-input>
					<el-input v-model="five" type="number">
						<template slot="prepend">
							2019
						</template>
						<template slot="append">
							万元
						</template>
					</el-input>
					<el-input v-model="six" type="number">
						<template slot="prepend">
							2020
						</template>
						<template slot="append">
							万元
						</template>
					</el-input>
				</el-form-item>
				<el-form-item label="研发费用：">
					<el-input v-model="seven" type="number">
						<template slot="append">
							万元
						</template>
					</el-input>
				</el-form-item>
			</div>
		</el-form>
		<el-button type="primary" style="margin: auto; text-align: center; display: block" round @click="submit">确认提交</el-button>
	</div>
</template>

<script>
import { Provider } from '@/common/js/api';
export default {
	data() {
		return {
			field: '', //1
			change: [], //2
			mix: ' ',
			affirm: '',
			satisfy: '', //3
			work: '',
			build: '',
			system: '',
			radio: '', //
			one: '',
			two: '',
			three: '',
			four: '',
			five: '',
			six: '',
			seven: '',
			activeName: 'first',
			options: [
				{
					value: '1',
					label: '0项'
				},
				{
					value: '2',
					label: '1项'
				},
				{
					value: '3',
					label: '2项'
				},
				{
					value: '4',
					label: '3项'
				},
				{
					value: '5',
					label: '4项'
				},
				{
					value: '6',
					label: '≥5项'
				}
			]
		};
	},

	created() {},
	methods: {
		submit() {
			// Provider
			this.$router.push({
				path: '/member2/innovation'
			});
		}
	}
};
</script>

<style lang="less" scoped>
.innovation-capability-form {
	line-height: 1;
	margin: 20px auto;
	padding: 32px 0 56px 38px;
	.form-title {
		color: #333;
		font-size: 18px;
		margin-bottom: 21px;
		font-weight: 700;
	}
	.out-title {
		margin-bottom: 33px;
	}
	/deep/.el-input {
		width: 360px;
	}
	.more-value /deep/.el-input {
		width: 240px;
		margin-left: 10px;
		margin-top: 10px;
	}
	.form-item-content {
		line-height: 32px;
	}
	.label {
		color: #333;
		font-size: 16px;
		width: auto;
		text-align: left;
		font-weight: 700;
		margin-left: 55px;
		margin-right: 0;
	}
	/deep/.el-radio-group {
		margin-left: 89px;
		width: auto;
		height: auto;
		line-height: 40px;
	}
	/deep/.el-radio__input.is-checked + .el-radio__label {
		color: #606266;
	}
}
</style>
