<template>
	<div class="content-box">
		<div class="title">创新能力评估报告</div>
		<el-tabs v-model="activeName">
			<el-tab-pane label="知识产权能力" name="first">
				<div class="existing">
					<div style="background-image: linear-gradient(#fff, #edf3ff)">
						<h4>现有情况</h4>
						<ul class="flex">
							<li>
								<p>{{ provider.fen }}</p>
								<p>专利信息</p>
							</li>
							<!-- <li>
                <p>{{provider.appearance}}</p>
                <p>外观设计</p>
              </li> -->
							<li>
								<p>{{ provider.shu }}</p>
								<p>版权信息</p>
							</li>
							<!-- <li>
                <p>{{provider.practical}}</p>
                <p>实用新型</p>
              </li> -->
						</ul>
					</div>

					<div class="result">
						<h4>评估结果</h4>
						<div class="flex">
							<div class="one">
								<div class="df">
									<p class="fs">{{ provider.fens }}</p>
									<p>知识产权能力</p>
									<p>满分30分</p>
								</div>
							</div>
							<div class="two">
								{{ provider.content }}
								<!-- 您已具备申请高新技术企业的条件。知识产权数量已达标，建议持续申请与主营产品相关的知识产权，为三年后的重新认定准备，增加知识产权的方式有购买/转让/自主申请。 -->
							</div>
							<div class="one">
								<div class="button" @click="chat">一键咨询</div>
								<div class="button" @click="activeName = 'second'">获取完整匹配报告</div>
							</div>
						</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="创新能力" name="second"><Test></Test></el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import Test from './test.vue';
// import Test1 from "./innovation.vue";
import { Info, Provider } from '@/common/js/api';

export default {
	components: {
		Test
		// Test1
	},
	data() {
		return {
			radio: '',
			input2: '',
			activeName: 'first',
			info: [],
			id: '',
			provider: {}
		};
	},

	created() {
		this.id = JSON.parse(localStorage.getItem('user')).id;
		this.Info();
	},
	methods: {
		Info() {
			Info({
				id: this.id
			})
				.then(res => {
					this.info = res.data;
					if (this.info.provider) {
						Provider({ keyword: this.info.provider.title })
							.then(res => {
								// console.log(res);
								this.provider = res.data;
							})
							.catch(() => {});
					} else {
						this.$confirm('请先注册服务商', '提示', {
							confirmButtonText: '注册',
							cancelButtonText: '取消',
							type: 'warning'
						})
							.then(() => {
								let routeData = this.$router.resolve({
									path: '/service_s'
								});
								window.open(routeData.href, '_blank');
							})
							.catch(() => {});
					}
				})
				.catch(() => {});
		},
		chat() {
			let routeData = this.$router.resolve({
				path: '/chat'
			});
			window.open(routeData.href, '_blank');
		}
	}
};
</script>

<style lang="less" scoped>
.content-box {
	padding: 20px 50px;

	.title {
		text-align: center;
		color: #6c90f8;
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	.existing {
		border: 1px solid #e2ecff;
		border-radius: 5px;
		margin: 0 50px;
		width: 850px;
		h4 {
			text-align: center;
			padding: 20px 0;
		}
		.flex {
			display: flex;
			text-align: center;
			padding: 10px 0;

			li {
				flex: 1;
			}
			li p:nth-child(1) {
				color: #6c90f8;
				font-size: 24px;
				font-weight: 700;
			}
			li p:nth-child(2) {
				font-size: 14px;
			}
		}
		.result {
			.flex {
				height: 106px;
				font-size: 12px;
				line-height: 1.5;
			}

			.one {
				flex: 1;
				.df {
					background: #6c90f8;
					height: 100%;
					width: 60%;
					margin: 0 auto;
					color: #fff;
					border-radius: 5px;
					padding: 10px 0;

					.fs {
						margin-top: 0;
						font-size: 18px;
						font-weight: 700;
					}
				}
				.button {
					cursor: pointer;
					width: 60%;
					color: #6c90f8;
					border: 1px solid #6c90f8;
					padding: 5px 0;
					border-radius: 20px;
					margin: 0 auto;
				}
				.button:nth-child(2) {
					color: #fff;
					background-color: #6c90f8;
					margin-top: 10px;
				}
			}
			.two {
				flex: 2;
				text-align: left;
			}
		}
	}
}
</style>
